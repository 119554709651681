import React from "react"
import {
  RiAppsLine,
  RiBookOpenLine,
  RiBrushLine,
  RiCameraLensLine,
} from "react-icons/ri"

export const tags = {
  all: {
    title: "All",
  },
  frontend: {
    title: "Front-end",
  },
  design: {
    title: "UX design",
  },
  mocks: {
    title: "UI mocks",
  },
  illustration: {
    title: "Illustration",
  },
  backend: {
    title: " Back-end",
  },
  fulltime: {
    title: "Full-time",
  },
  sideproject: {
    title: "Side projects",
  },
  opensource: {
    title: "Open source",
  },
}

export const projects = [
  {
    title: "Assembled",
    subtitle: "Modern workforce management",
    image: "assembled.jpg",
    tags: ["design", "mocks", "frontend", "fulltime"],
    url: "/assembled",
    importance: 22,
    date: new Date(2020, 3, 30),
    featured: true,
    status: "live",
    tools: ["reactjs", "typescript", "golang", "figma", "flow"],
    involved: [
      "Front-end",
      "Performace improvements",
      "Feature prototypes",
      "UI design",
      "Customer interactions",
    ],
    websites: [
      {
        url: "https://www.assembled.com/",
        label: "Website",
      },
    ],
    "people-business": ["kaytlin", "jb", "talal", "megha", "ryan", "brian"],
    "people-design": ["luke_andrew", "shannon", "steph"],
    "people-engineers": [
      "wei-wei",
      "taylor",
      "neel",
      "michael",
      "kadeem",
      "ralles",
      "john",
    ],
  },
  {
    title: "ConstructivIQ",
    subtitle: "???",
    image: "??",
    tags: ["design", "mocks"],
    url: "/constructiviq",
    importance: 17.6,
    date: new Date(2022, 10, 1),
    featured: true,
    status: "live",
    tools: ["figma"],
    involved: ["UI design"],
    locked: true,
    websites: [
      {
        url: "http://lark.systems/",
        label: "Website",
      },
    ],
    people: ["luke"],
  },
  {
    title: "KatiKati",
    subtitle: "Large scale 1:1 conversations",
    image: "katikati.jpg",
    tags: ["opensource", "mocks", "frontend"],
    url: "/katikati",
    importance: 21,
    date: new Date(2020, 3, 30),
    featured: true,
    status: "live",
    tools: ["dart", "figma", "firebase"],
    involved: ["Front-end development", "Feature prototypes", "UI design"],
    websites: [
      {
        url: "https://github.com/larksystems/nook",
        label: "Github",
      },
      {
        url: "http://lark.systems/",
        label: "Website",
      },
    ],
    people: ["luke", "mariana", "sharath"],
  },
  {
    title: "Semantics3",
    subtitle: "Ecommerce data solutions",
    image: "semantics3.jpg",
    tags: ["design", "mocks", "frontend", "fulltime"],
    url: "/semantics3",
    importance: 20,
    date: new Date(2015, 3, 30),
    featured: true,
    status: "acquired",
    tools: ["reactjs", "illustrator"],
    involved: [
      "UI design framework",
      "Front-end",
      "Detailed interactions",
      "Prototypes",
    ],
    websites: [
      {
        url: "https://www.semantics3.com/",
        label: "Website",
      },
      {
        url: "https://www.ycombinator.com/companies/semantics3",
        label: "YCombinator",
      },
    ],
    people: ["amar", "arpit", "vinoth", "varun", "anjali"],
  },
  {
    title: "Autodesk Dynamo",
    subtitle: "Graphical programming",
    image: "autodesk-dynamo.jpg",
    tags: ["design", "mocks", "fulltime", "opensource"],
    url: "/autodesk-dynamo",
    importance: 19,
    date: new Date(2012, 4, 30),
    status: "live",
    tools: ["illustrator", "pencil"],
    people: ["luke", "ben"],
    websites: [{ url: "", label: "Website" }],
    involved: ["Conceptual design", "Detailed interactions", "User studies"],
    featured: true,
  },
  {
    title: "Africa's voices",
    subtitle: "Amplifying the voices of citizens",
    image: "africas-voices.jpg",
    tags: ["design", "frontend"],
    url: "/africas-voices",
    importance: 18,
    date: new Date(2020, 2, 30),
    status: "delivered",
    tools: ["dart", "illustrator"],
    involved: ["Tools for rapid data exploration", "Interactive explainers"],
    people: ["luke", "sharath"],
    websites: [
      {
        url: "https://www.africasvoices.org/",
        label: "Website",
      },
    ],
    featured: true,
  },
  {
    title: "Augmented reality",
    subtitle: "Experiements with AR",
    image: "augmented-reality.jpg",
    tags: ["sideproject", "frontend"],
    url: "/augmented-reality",
    importance: 17.5,
    date: new Date(2020, 3, 30),
    tools: ["swift", "arkit"],
    status: "prototype",
    featured: true,
  },
  {
    title: "Hackathons",
    subtitle: "Ship in 48 hours",
    image: "hackathons.jpg",
    tags: ["sideproject"],
    url: "/hackathons",
    importance: 17.4,
    date: new Date(2020, 2, 30),
    status: "prototype",
    featured: true,
  },
  {
    title: "Klinify",
    subtitle: "Clinic management software",
    image: "klinify.jpg",
    tags: ["design", "mocks", "frontend", "backend"],
    url: "/klinify",
    importance: 17,
    date: new Date(),
    status: "delivered",
    people: ["nishanth", "krishsuren"],
    websites: [
      {
        url: "https://www.klinify.com/",
        label: "Website",
      },
    ],
    involved: ["Prototypes", "Building front-end / some backend"],
  },
  {
    title: "HorseRoof",
    subtitle: "Everything polo",
    image: "horseroof.jpg",
    tags: ["mocks", "frontend", "backend"],
    url: "/horseroof",
    importance: 16,
    date: new Date(),
    status: "delivered",
    involved: ["Building & maintaining tech", "Social media"],
    people: ["anne"],
  },
  {
    title: "Know",
    subtitle: "Work management",
    image: "knowapp.jpg",
    tags: ["frontend"],
    url: "/know-app",
    importance: 15,
    date: new Date(2019, 8, 30),
    status: "delivered",
    websites: [{ url: "http://getknowapp.com/", label: "Website" }],
    involved: ["Front-end", "Whatsapp bot", "Prototyping"],
    tools: ["reactjs", "firebase", "twilio"],
    people: ["krishshri"],
  },

  {
    title: "BlockPunk",
    subtitle: "Anime over blockchain",
    image: "blockpunk.jpg",
    tags: ["mocks", "frontend"],
    url: "/blockpunk",
    importance: 14,
    date: new Date(2018, 8, 30),
    status: "delivered",
    websites: [
      {
        url: "https://blockpunk.net/en/marketplace",
        label: "Website",
      },
    ],
    people: ["jatin", "julian"],
    tools: ["reactjs", "ethereum", "illustrator"],
  },
  {
    title: "Nimbus for Work",
    subtitle: "Office management solutions",
    image: "nimbus.jpg",
    tags: ["mocks", "frontend"],
    url: "/nimbus-for-work",
    importance: 13,
    date: new Date(2019, 5, 30),
    status: "delivered",
    tools: ["react-native", "figma"],
    people: ["paul"],
    websites: [
      {
        url: "https://nimbusforwork.com/",
        label: "Website",
      },
    ],
  },
  {
    title: "Robin",
    subtitle: "Reference check for employers",
    image: "robin.jpg",
    tags: ["design", "mocks", "frontend"],
    url: "/robin",
    status: "delivered",
    importance: 12,
    date: new Date(2019, 3, 30),
    people: ["siddharth", "ardy"],
    tools: ["reactjs", "nodejs", "illustrator", "figma"],
    websites: [
      {
        url: "https://www.hirerobin.ai/",
        label: "Website",
      },
    ],
  },
  {
    title: "NUS GRIP",
    subtitle: "Prototypes for startups",
    image: "nus.jpg",
    tags: ["frontend"],
    url: "/nus-grip",
    status: "prototype",
    importance: 11,
    date: new Date(2019, 9, 30),
  },
  {
    title: "Learnographics",
    subtitle: "Read books visually",
    image: "learnographics.jpg",
    tags: ["illustration", "sideproject"],
    url: "/learnographics",
    importance: 10,
    date: new Date(2014, 8, 30),
    status: "ceased",
    tools: ["pencil", "illustrator"],
    involved: ["Illustration", "Book reading"],
    people: ["rohan"],
    websites: [
      {
        url: "https://learnographics.netlify.app/",
        label: "Website",
      },
    ],
  },
  {
    title: "Dearpost",
    subtitle: "Send your photos as postcards",
    image: "dearpost.jpg",
    tags: ["illustration", "frontend", "sideproject"],
    url: "/dearpost",
    importance: 8,
    date: new Date(2019, 1, 28),
    status: "ceased",
    tools: ["shopify"],
    people: ["nagappan", "swetha"],
    involved: [
      "Building the platform",
      "Collaboration with artists",
      "Business development",
    ],
    websites: [
      {
        url:
          "https://tech.economictimes.indiatimes.com/news/startups/this-bengaluru-startup-is-helping-people-send-personal-postcards-to-their-loved-ones/65127320",
        label: "IndiaTimes",
      },
      {
        url: "https://thegreyalley.com/dearpost-online-postcard-platform/",
        label: "The Gray Alley",
      },
      {
        url: "https://lbb.in/bangalore/love-postcard-swapping-check-0e9ea6/",
        label: "LBB.in",
      },
    ],
  },
  {
    title: "iOS Apps",
    subtitle: "Utility apps",
    image: "ios.jpg",
    tags: ["frontend", "backend", "sideproject"],
    url: "/ios-apps",
    importance: 7.5,
    date: new Date(2019, 1, 28),
    status: "not_maintained",
    tools: ["swift", "firebase"],
  },
  {
    title: "RetailKit",
    subtitle: "Identify your influencers",
    image: "retailkit.jpg",
    tags: ["design", "sideproject", "frontend", "backend"],
    url: "/retailkit",
    importance: 6,
    date: new Date(),
    status: "live",
    people: ["anjali"],
    websites: [
      {
        url: "https://retailkit.io/influencer/",
        label: "Website",
      },
      {
        url:
          "http://insta.retailkit.io/reports/tradamarket/07-May-2020-13-May-2020.html",
        label: "Report: @TradaMarket",
      },
      {
        url:
          "http://insta.retailkit.io/reports/glancescreen/01-Dec-2019-29-Feb-2020.html",
        label: "Report: @GlanceScreen",
      },
    ],
    tools: ["reactjs", "nodejs"],
    involved: ["Front-end", "Instagram crawling", "Business development"],
  },
  {
    title: "NeonLaksa",
    subtitle: "Colouring book for Singapore's foodies",
    image: "neonlaksa.jpg",
    tags: ["illustration", "frontend", "sideproject"],
    url: "/neonlaksa",
    importance: 5,
    date: new Date(2019, 4, 30),
    status: "live",
    tools: ["pencil", "procreate"],
    involved: ["Cover design", "Outline illustration", "Sales"],
    websites: [
      {
        url: "https://neonlaksa.netlify.app/",
        label: "Online store",
      },
    ],
    people: ["anjali"],
  },
  {
    title: "RealLeader interviews",
    subtitle: "50+ Interviews with everyday leaders",
    image: "realleaders.jpg",
    tags: ["sideproject"],
    status: "ceased",
    url: "/real-leaders",
    importance: 5.5,
    date: new Date(2016, 4, 30),
    websites: [
      {
        url: "https://www.youtube.com/user/RealLeaderInterviews/videos",
        label: "Youtube channel",
      },
    ],
    involved: ["Interviews", "Video editing"],
    people: ["rohan", "dhanya"],
  },
  {
    title: "Static Websites",
    subtitle: "Landing pages for companies.",
    image: "static.jpg",
    tags: ["frontend"],
    url: "/static-websites",
    importance: 6.5,
    date: new Date(2019, 5, 30),
  },
  {
    title: "Contextual.ly",
    subtitle: "Read articles with context",
    image: "contextually.jpg",
    tags: ["frontend", "backend", "sideproject"],
    url: "/contextually",
    importance: 1,
    date: new Date(2016, 2, 30),
    status: "not_maintained",
    tools: ["reactjs", "nodejs"],
    websites: [
      {
        url:
          "https://chrome.google.com/webstore/detail/contextually-app/lkppkhgpnnhlchbeialcjkdokfienhli?hl=en",
        label: "Chrome extension",
      },
    ],
    people: ["nagappan"],
  },
]

export const interests = [
  {
    title: "Reading",
    image: "",
    isExternal: true,
    url:
      "https://www.goodreads.com/review/list/8968974-elayabharath?shelf=read",
    icon: <RiBookOpenLine />,
  },
  {
    title: "Sketchbook",
    image: "",
    url: "/sketchbook",
    icon: <RiBrushLine />,
  },
  {
    title: "Logo design",
    image: "",
    url: "/logo-design",
    icon: <RiAppsLine />,
  },
  {
    title: "Photography",
    image: "",
    url: "/photography",
    icon: <RiCameraLensLine />,
  },
]
