import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { projects, tags } from "../data"
import { Link } from "gatsby"
import Carousel, { Dots } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

export const Title = ({ heading, sub, children }) => (
  <div className="page-title">
    <h1>{heading}</h1>
    <p style={{ marginBottom: 0 }}>{sub}</p>
    {children}
  </div>
)

export const Project = ({ heading, sub, style = {}, children }) => (
  <div className="project-title" style={style}>
    <h2>{heading}</h2>
    <p style={{ marginBottom: 0 }}>{sub}</p>
    {children}
  </div>
)

const badge = {
  ceased: {
    label: "Ceased",
    color: "danger",
  },
  not_maintained: {
    label: "Not maintained",
    color: "orange",
  },
  acquired: {
    label: "Acquired",
    color: "danger",
  },
  live: {
    label: "Live",
    color: "green",
  },
  prototype: {
    label: "Prototype",
    color: "orange",
  },
  delivered: {
    label: "Delivered",
    color: "green",
  },
  consult: {
    label: "Consulted",
    color: "green",
  },
}

export const Overview = ({ project, text }) => {
  const proj = projects.find(p => p.url === `/${project}`)
  const status = badge[proj.status]

  return (
    <div className="section-bottom">
      <Row>
        <Col lg={3} md={3} sm={12} xs={12}>
          <h4>Overview</h4>
        </Col>
        <Col>
          {text.map((t, i) => (
            <p key={i}>{t}</p>
          ))}
          <div style={{ marginTop: 32 }}>
            {proj.tags.map(t => (
              <span key={t} className="text-tag">
                {tags[t].title}
              </span>
            ))}
            <span className={`text-tag ${status.color}`}>{status.label}</span>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const Process = ({ children }) => (
  <div className="section-bottom">
    <Row>
      <Col lg={3} md={3} sm={12} xs={12}>
        <h4>Process</h4>
      </Col>
      <Col>{children}</Col>
    </Row>
  </div>
)

export const TitleContent = ({ title, children }) => (
  <div className="section-bottom">
    <Row>
      <Col lg={3} md={3} sm={12} xs={12}>
        <h4>{title}</h4>
      </Col>
      <Col lg={9} md={9} sm={12} xs={12}>
        {children}
      </Col>
    </Row>
  </div>
)

const toolset = {
  illustrator: {
    title: "Adobe Illustrator",
    icon: "illustrator.png",
  },
  photoshop: {
    title: "Adobe photoshop",
    icon: "photoshop.png",
  },
  pencil: {
    title: "Pencil on paper",
    icon: "pencil.png",
  },
  lightroom: {
    title: "Adobe Lightroom",
    icon: "lightroom.png",
  },
  sketchbook: {
    title: "Autodesk Sketchbook",
    icon: "sketchbook.png",
  },
  procreate: {
    title: "Procreate",
    icon: "procreate.png",
  },
  paint: {
    title: "Paint on canvas",
    icon: "paint.png",
  },
  reactjs: {
    title: "React JS",
    icon: "react.png",
  },
  nodejs: {
    title: "Node JS",
    icon: "node.png",
  },
  shopify: {
    title: "Shopify",
    icon: "shopify.png",
  },
  gatsbyjs: {
    title: "Gatsby JS",
    icon: "gatsby.png",
  },
  arkit: {
    title: "ARKit",
    icon: "arkit.png",
  },
  swift: {
    title: "Swift",
    icon: "swift.png",
  },
  "react-native": {
    title: "React Native",
    icon: "react-native.png",
  },
  figma: {
    title: "Figma",
    icon: "figma.png",
  },
  ethereum: {
    title: "Ethereum",
    icon: "ethereum.png",
  },
  airtable: {
    title: "Airtable",
    icon: "airtable.png",
  },
  firebase: {
    title: "Firebase",
    icon: "firebase.png",
  },
  lambda: {
    title: "AWS Lambda",
    icon: "lambda.png",
  },
  twilio: {
    title: "Twilio",
    icon: "twilio.png",
  },
  dart: {
    title: "Dart",
    icon: "dart.png",
  },
  csharp: {
    title: "C#",
    icon: "csharp.png",
  },
  python: {
    title: "Python",
    icon: "python.png",
  },
  typescript: {
    title: "TypeScript",
    icon: "typescript.png",
  },
  flow: {
    title: "Flow JS",
    icon: "flow.png",
  },
  golang: {
    title: "Go",
    icon: "golang.png",
  },
}

export const Toolset = ({ project, tools }) => {
  if (project) {
    const proj = projects.find(p => p.url === `/${project}`)
    tools = proj.tools
  }

  return (
    <div className="section-bottom">
      <Row>
        <Col lg={3} md={3} sm={12} xs={12}>
          <h4>Toolset</h4>
        </Col>
        <Col>
          {tools.map(t => (
            <span className="text-tag" key={t}>
              <img
                src={
                  "/images/tools/" +
                  (!toolset[t] ? "default.png" : toolset[t].icon)
                }
                alt="logo"
              />
              <span>{!toolset[t] ? t : toolset[t].title}</span>
            </span>
          ))}
        </Col>
      </Row>
    </div>
  )
}

export const Involvement = ({ project, involved }) => {
  if (project) {
    const proj = projects.find(p => p.url === `/${project}`)
    involved = proj.involved
  }

  return (
    <div className="section-bottom">
      <Row>
        <Col lg={3} md={3} sm={12} xs={12}>
          <h4>Involved in</h4>
        </Col>
        <Col lg={9} md={9} sm={12} xs={12}>
          {involved.map(i => (
            <span className="text-tag" key={i}>
              {i}
            </span>
          ))}
        </Col>
      </Row>
    </div>
  )
}

export const Visit = ({ project, label, websites }) => {
  if (project) {
    const proj = projects.find(p => p.url === `/${project}`)
    websites = proj.websites
  }

  return (
    <div className="section-bottom">
      <Row>
        <Col lg={3} md={3} sm={12} xs={12}>
          <h4>{label || "Visit"}</h4>
        </Col>
        <Col lg={9} md={9} sm={12} xs={12}>
          {websites.map(website => (
            <ExternalLink
              key={website.url}
              href={website.url}
              label={website.label}
              style={{ marginRight: 8, marginBottom: 8 }}
            />
          ))}
        </Col>
      </Row>
    </div>
  )
}

export const Youtube = ({ src }) => (
  <iframe
    title="embed"
    width="100%"
    height="420"
    src={`${src}?modestbranding=1&rel=0`}
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  />
)

export const ExternalLink = ({ href, label, style = {}, block }) => {
  let classNames = "border-link"
  if (block) {
    classNames = `${classNames} border-link-block`
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames}
      style={style}
    >
      {label}&nbsp;&nbsp;&nbsp;↗
    </a>
  )
}

export const InternalLink = ({ to, label, style = {}, block }) => {
  let classNames = "border-link"
  if (block) {
    classNames = `${classNames} border-link-block`
  }
  return (
    <Link to={to} className={classNames} style={style}>
      {label}&nbsp;&nbsp;&nbsp;→
    </Link>
  )
}

const allPeople = {
  anjali: {
    name: "Anjali Krishnan",
    url: "https://www.linkedin.com/in/anjali-krishnan/",
  },
  nagappan: {
    name: "Nagappan Nachiappan",
    url: "https://www.linkedin.com/in/nagappan-nachiappan/",
  },
  rohan: {
    name: "Rohan Rajiv",
    url: "https://www.linkedin.com/in/rohanrajiv/",
  },
  dhanya: {
    name: "Dhanya Balasubramanian",
    url: "https://www.linkedin.com/in/dhanyabalasubramanian/",
  },
  kunal: {
    name: "Kunal Lunawat",
    url: "https://www.linkedin.com/in/kunallunawat/",
  },
  eric: {
    name: "Erick Rabins",
    url: "https://www.linkedin.com/in/erick-rabins-7630961/",
  },
  swetha: {
    name: "Swetha Kanithi",
    url: "https://www.linkedin.com/in/swetha-kanithi-3136384a/",
  },
  paul: {
    name: "Paul Copplestone",
    url: "https://www.linkedin.com/in/paulcopplestone/",
  },
  jatin: {
    name: "Jatin Shan",
    url: "https://www.linkedin.com/in/shahjatin/",
  },
  julian: {
    name: "Julian Lai-Hung",
    url: "https://www.linkedin.com/in/julianxdotnet/",
  },
  siddharth: {
    name: "Siddharth Kumar",
    url: "https://www.linkedin.com/in/siddharthkumar180679/",
  },
  ardy: {
    name: "Ardy Satria Hasanuddin",
    url: "https://www.linkedin.com/in/ardysatria/",
  },
  anne: {
    name: "Anne Krag Sørensen",
    url: "https://www.linkedin.com/in/anne-krag-s%C3%B8rensen-39240957/",
  },
  krishshri: {
    name: "Krish Sridhar",
    url: "https://www.linkedin.com/in/akrishsridhar/",
  },
  luke: {
    name: "Luke Church",
    url: "http://lukechurchnet.appspot.com/",
  },
  sharath: {
    name: "Sharath Srinivasan",
    url: "https://www.linkedin.com/in/sharath-srinivasan-b70603/",
  },
  mariana: {
    name: "Mariana Mărășoiu",
    url: "https://www.linkedin.com/in/marianamarasoiu/",
  },
  ben: {
    name: "Beng Lin Goh",
    url: "https://www.linkedin.com/in/benglin/",
  },
  ramanan: {
    name: "Ramanan Balakrishnan",
    url: "https://www.linkedin.com/in/ramananbalakrishnan/",
  },
  abishek: {
    name: "Abishek Bhat",
    url: "https://www.linkedin.com/in/abishek-bhat-a5985616/",
  },
  vanchi: {
    name: "Vanchinathan Venkataramani",
    url: "https://www.linkedin.com/in/vanchinathan-venkataramani-0bb66026/",
  },
  divya: {
    name: "Divya Sivasankaran",
    url: "https://www.linkedin.com/in/divsivasankaran/",
  },
  nishanth: {
    name: "Nishanth Sudharsanam",
    url: "https://www.linkedin.com/in/nishanth-sudharsanam-6a861913/",
  },
  krishsuren: {
    name: "Krishanthan Surendran",
    url: "https://www.linkedin.com/in/skrishanthan/",
  },
  vinothgeo: {
    name: "Vinod Ramanarayanan",
    url: "https://www.linkedin.com/in/vinod-ramanarayanan/",
  },
  amar: {
    name: "Amarnath Ravikumar",
    url: "https://www.linkedin.com/in/amarnus/",
  },
  arpit: {
    name: "Arpit Agarwal",
    url: "https://www.linkedin.com/in/arpit-agarwal-5165975b/",
  },
  vinoth: {
    name: "Vinoth Gopinath",
    url: "https://www.linkedin.com/in/vinothgopi/",
  },
  varun: {
    name: "Varun Sivamani",
    url: "https://www.linkedin.com/in/netvarun/",
  },
  ryan: {
    name: "Ryan Wang (CEO)",
    url: "https://www.linkedin.com/in/ryanywang/",
  },
  taylor: {
    name: "Taylor Milliman",
    url: "https://www.linkedin.com/in/taylor-milliman/",
  },
  ralles: {
    name: "Ralles Liu",
    url: "https://www.linkedin.com/in/ralles-liu/",
  },
  "wei-wei": {
    name: "Wei-Wei W",
    url: "https://www.linkedin.com/in/hungweiwu/",
  },
  shannon: {
    name: "Shannon Miwa",
    url: "http://shannonmiwa.com/",
  },
  steph: {
    name: "Stephanie Cheng",
    url: "https://www.linkedin.com/in/stephcheng10/",
  },
  neel: {
    name: "Neel Kapse",
    url: "https://www.linkedin.com/in/neel-kapse/",
  },
  michael: {
    name: "Michael Zeng",
    url: "https://www.linkedin.com/in/michael-zeng-a69a29a2/",
  },
  kaytlin: {
    name: "Kaytlin Louton",
    url: "https://www.linkedin.com/in/kaytlin-louton-0296ab48/",
  },
  john: {
    name: "John Wang (CTO)",
    url: "https://www.linkedin.com/in/johnjianwang/",
  },
  brian: {
    name: "Brian Sze (Founder)",
    url: "https://www.linkedin.com/in/briansze/",
  },
  kadeem: {
    name: "Kadeem Nibbs",
    url: "https://www.linkedin.com/in/kadeem-nibbs-238b2b117/",
  },
  jb: {
    name: "JB Bolber",
    url: "https://www.linkedin.com/in/jessica-bolber-4a09136b/",
  },
  talal: {
    name: "Talal Naboulsi",
    url: "https://www.linkedin.com/in/naboulsitalal/",
  },
  megha: {
    name: "Megha Narayan",
    url: "https://www.linkedin.com/in/meghanarayan/",
  },
  luke_andrew: {
    name: "Luke Andrews",
    url: "https://www.linkedin.com/in/attaboy/",
  },
}

export const WorkedWith = ({ project, label, people, peopleKey }) => {
  if (project) {
    const proj = projects.find(p => p.url === `/${project}`)
    if (peopleKey) {
      people = proj[peopleKey]
    } else {
      people = proj.people
    }
  }

  return (
    <div className="section-bottom">
      <Row>
        <Col lg={3} md={3} sm={12} xs={12}>
          <h4>{label || "Worked with"}</h4>
        </Col>
        <Col lg={9} md={9} sm={12} xs={12}>
          {people.sort().map(p => (
            <ExternalLink
              key={p}
              href={allPeople[p].url}
              label={allPeople[p].name}
              style={{ marginRight: 8, marginBottom: 8 }}
            />
          ))}
        </Col>
      </Row>
    </div>
  )
}

export const Contributions = ({ text = [] }) => {
  return (
    <div className="section-gray">
      {text.map((content, i) => (
        <p key={i}>⟡ {content}</p>
      ))}
    </div>
  )
}

export const Car = ({ images, bordered, hideTop, hideBottom = false }) => {
  const [value, setValue] = useState(0)

  return (
    <div>
      {!hideTop && (
        <Dots
          value={value}
          onChange={value => setValue(value)}
          thumbnails={images.map(i => (
            <img
              src={i}
              alt="dashboard"
              className="img-thumbnail bordered"
              key={i}
            />
          ))}
        />
      )}
      <Carousel
        autoPlay={10000}
        stopAutoPlayOnHover
        infinite
        value={value}
        onChange={value => setValue(value)}
        centered={false}
      >
        {images.map(i => (
          <img
            src={i}
            alt="dashboard"
            key={i}
            className={bordered ? "bordered" : ""}
          />
        ))}
      </Carousel>
      {!hideBottom && (
        <Dots
          value={value}
          onChange={value => setValue(value)}
          thumbnails={images.map(i => (
            <img
              src={i}
              alt="dashboard"
              className="img-thumbnail bordered"
              key={i}
            />
          ))}
        />
      )}
    </div>
  )
}
